<template>
    <div class="profile-box">
        <div class="profile-image" :style="{ 'background-image' : `url(${profileCover}`}" />
        <block-user-modal @toggle-block-user="toggleBlockUser" />
        <div class="container profile-container">
            <router-link :to="{ name: 'web-app-profile-settings', params: { displayName: userData.displayname } }" class="user-settings d-flex d-md-none">
                <img src="@assets/img/icons/three-dots.svg">
            </router-link>
            <div class="row no-gutters flex-nowrap">
                <div class="col">
                    <div class="row no-gutters flex-md-nowrap" :class="{ 'align-items-center' : userData.profile_blocked }">
                        <div class="col-12 col-md-auto">
                            <user-picture :is-verified="Boolean(Number(userData.verify))" :picture="userData.photo ? userData.photo.url : ''" />
                        </div>
                        <div class="col-12 col-md">
                            <user-name :name="`${userData.firstname} ${userData.lastname}`" />
                            <user-nickname :user-nickname="userData.displayname" />
                            <span v-if="userData.description && !userData.profile_blocked" class="user-description">{{ userData.description }}</span>
                            <div v-if="!userData.profile_blocked" class="row no-gutters">
                                <!-- <div class="col-auto">
                                    <div class="user-location">
                                        <img src="@assets/img/icons/location.svg">
                                        Los Angeles, CA
                                    </div>
                                </div> -->
                                <div class="col-auto">
                                    <div class="user-joined">
                                        <img src="@assets/img/icons/calendar.svg">
                                        Joined {{ userRegisteredAt }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="!userData.profile_blocked" class="row no-gutters">
                                <div class="col-auto">
                                    <div class="user-memods-created">
                                        <strong>{{ userData.total_memos }}</strong>
                                        <span>Memos</span>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="user-following" @click="$emit('show-user-following')">
                                        <strong>{{ userData.total_following }}</strong>
                                        <span>Following</span>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="user-followers" @click="$emit('show-user-followers')">
                                        <strong>{{ userData.total_followers }}</strong>
                                        <span>Followers</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="d-flex align-items-center">
                        <notification-bell v-if="!isLoggedUserProfile && userData.is_following && !userData.is_blocked && !userData.profile_blocked" :current-user-data="userData" />
                        <a
                            v-if="isLoggedUserProfile"
                            class="btn edit-profile"
                            href="javascript:void(0)"
                            @click="$modal.show('edit-profile')"
                        >
                            Edit Profile
                        </a>
                        <follow-user-button v-if="!isLoggedUserProfile && !userData.is_blocked && !userData.profile_blocked" :user-to-follow="userData" @toggled-follow="(is_following) => $emit('toggled-follow', is_following)" />
                        <button v-if="!isLoggedUserProfile && userData.is_blocked" class="unblock-user">
                            Blocked
                        </button>
                        <div v-if="!isLoggedUserProfile && isLoggedIn" class="more-options">
                            <v-popover>
                                <div class="tooltip-target b3">
                                    <img src="@assets/img/icons/three-dots.svg" alt="More options">
                                </div>
                                <template slot="popover">
                                    <div class="popover-options">
                                        <div
                                            v-close-popover
                                            class="popover-option"
                                            @click="handleBlockUser"
                                        >
                                            <span>{{ userData.is_blocked ? `Unblock ${userData.firstname} ${userData.lastname}` : 'Block user' }}</span>
                                            <svg
                                                class="ml-3"
                                                width="26"
                                                height="26"
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5.2282 4.52352C7.27491 2.64596 10.0036 1.5 13 1.5C19.3513 1.5 24.5 6.64873 24.5 13C24.5 16.0714 23.2959 18.8616 21.334 20.9243L5.2282 4.52352ZM4.52117 5.23076C2.64501 7.27718 1.5 10.0049 1.5 13C1.5 19.3513 6.64873 24.5 13 24.5C15.9201 24.5 18.586 23.4116 20.6142 21.6184L4.52117 5.23076ZM13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                            </v-popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formatDate } from "@/utils";
import { parseISO } from "date-fns";

export default {
    name: "ProfileBox",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@/components/atoms/user-picture"),
        UserName: () => import(/* webpackChunkName: "user-name" */ "@/components/atoms/user-name"),
        UserNickname: () => import(/* webpackChunkName: "user-nickname" */ "@/components/atoms/user-nickname"),
        FollowUserButton: () => import(/* webpackChunkName: "follow-user-button" */ "@c/atoms/follow-user-button"),
        NotificationBell: () => import(/* webpackChunkName: "notification-bell" */ "@c/atoms/notification-bell"),
        BlockUserModal: () => import(/* webpackChunkName: "block-user-modal" */ "@/components/organisms/modals/block-user")
    },
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        }),
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        isLoggedUserProfile() {
            return Number(this.userData.id) == Number(this.userId);
        },
        userRegisteredAt() {
            const { registered } = this.userData;
            return formatDate(parseISO(registered), "MMM yyyy");
        },
        profileCover() {
            return this.userData.cover_image?.url || require("@assets/img/profile-cover-placeholder.jpg");
        }
    },
    methods: {
        formatDate,
        toggleBlockUser() {
            axiosV2.post(`/block-users/${this.userData.id}`).then(({ data }) => {
                this.$emit("toggle-block-user", data.is_blocked);
            })
        },
        handleBlockUser() {
            if (this.userData.is_blocked) {
                this.toggleBlockUser();
            } else {
                this.$modal.show("block-user-modal", { userId: this.userData.id, userName: `${this.userData.firstname} ${this.userData.lastname}` })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-box {
    position: relative;

    .profile-image {
        height: 200px;
        background-position: center;
        background-size: cover;
        position: absolute;
        width: 100%;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(29,29,32,0) 100%);
        }
    }

    .profile-container  {
        position: relative;
        padding-top: 140px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .user-settings {
            position: absolute;
            right: 0;
            top: 0;
            width: 55px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .edit-profile {
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 30px;
            text-decoration: none;
            font-weight: 400;
            display: block;
        }

        @media(max-width: $md) {
            .edit-profile,
            .follow-user-button /deep/ .btn {
                padding: 8px 15px !important;
            }

            .edit-profile,
            .follow-user-button {
                margin-top: 135px;
            }
        }
    }

    /deep/ .user-picture {
        figure {
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        margin-right: 25px;

        @media(max-width: $md) {
            margin-bottom: 25px;
        }
    }

    /deep/ .user-name {
        span {
            @extend .h2;
            line-height: 22px;
        }
        margin-bottom: 3px;
    }

    /deep/ .user-nickname {
        span {
            @extend .body-text;
        }

        margin-bottom: 10px;
    }

    .user-description {
        @extend .body-text;
        line-height: 19px;
        word-break: break-word;
    }

    .user-location,
    .user-joined {
        @extend .body-text;
        display: flex;
        align-items: center;
        line-height: 24px;
        color: rgba(235,235,245,0.6);
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 15px;

        img {
            margin-right: 5px;
        }
    }

    .user-following {
        cursor: pointer;
    }

    .unblock-user {
        padding: 10px 20px;
        border: 1px solid $error-color;
        background-color: white;
        border-radius: 20px;
        font-size: 14px;
        pointer-events: none;
    }

    .more-options {
        margin-left: 10px;
        background-color: rgba(0, 0, 0, .5);
        cursor: pointer;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user-memods-created,
    .user-following,
    .user-followers {
        @extend .body-text;
        display: flex;
        align-items: baseline;
        line-height: 14px;
        color: rgba(235,235,245,0.6);
        margin-right: 10px;
        cursor: pointer;

        strong {
            color: white;
            font-weight: 600;
            display: block;
            margin-right: 5px;
        }
    }
}
</style>
